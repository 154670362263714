import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';

import { persistedReducer } from './rootReducer';

const logger = createLogger({
  predicate: () => process.env.NODE_ENV !== 'production',
});
const middlewares = [logger];

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);

export const persistor = persistStore(store);
