import React from 'react';
import HomeContact from '../components/HomeContact';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

export default function AboutPage() {
	const { t } = useTranslation();

	return (
		<div className='about-page'>
			<div class='jumbotron'>
				<img
					src={require('../assets/images/about/nos-marque.jpg').default}
					alt=''
				/>
			</div>
			<div className='about-section  header-before'>
				<InView threshold={0.25}>
					{({ ref, inView, entry }) => (
						<>
							<h2 ref={ref} className={inView ? 'animIn' : 'animOut'}>
								{t('about-us-title')}
							</h2>
						</>
					)}
				</InView>

				<p>{t('about-us-lead1')}</p>
				<p>{t('about-us-lead2')}</p>
				<p>{t('about-us-lead3')}</p>
			</div>

			<div className='flex-2-div'>
				<div className='side img-side'></div>
				<div className='side text-side'>
					<div className='header-before-left'>
						<InView threshold={0.25}>
							{({ ref, inView, entry }) => (
								<>
									<h2 ref={ref} className={inView ? 'animIn' : 'animOut'}>
										{t('about-us-section1-title')}
									</h2>
								</>
							)}
						</InView>
						<p>{t('about-us-section1-paragraph1')}</p>
						<br />
						<p>{t('about-us-section1-paragraph2')}</p>
					</div>
				</div>
			</div>

			<div className='flex-2-div'>
				<div className='side text-side'>
					<div className='header-before-left'>
						<InView threshold={0.25}>
							{({ ref, inView, entry }) => (
								<>
									<h2
										ref={ref}
										className={inView ? 'animIn' : 'animOut'}
										dangerouslySetInnerHTML={{
											__html: t('about-us-section2-title1'),
										}}></h2>
								</>
							)}
						</InView>					    
						<h5>{t('about-us-section2-paragraph1-title')}</h5>
						<p>{t('about-us-section2-paragraph1')}</p>
						<h5>{t('about-us-section2-paragraph2-title')}</h5>
						<p>{t('about-us-section2-paragraph2')}</p>
						<h5>{t('about-us-section2-paragraph3-title')}</h5>
						<p>{t('about-us-section2-paragraph3')}</p>
						
					</div>
					<div className='side img-side'></div>
				</div>
			</div>

			<HomeContact />
		</div>
	);
}
