import axios from 'axios';
import { useState } from 'react';

const useFetch = () => {
	const [request, setRequest] = useState(null);
	// add https://biospherelab.dz for local testing
	const contactUrl = '/app/contact';
	const carrierUrl = '/app/career';
	const contact = async (data) => {
		setRequest('loading');
		await axios
			.post(contactUrl, data)
			.then((res) => setRequest('done'))
			.catch((err) => setRequest('error'));
	};
	const carrier = async (data) => {
		console.log(data.get('nom'));
		setRequest('loading');
		console.log(data)
		await axios
			.post(carrierUrl, data)
			.then((res) => setRequest('done'))
			.catch((err) => setRequest('error'));
	};
	return { contact, carrier, request };
};

export default useFetch;
