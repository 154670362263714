const initialState = {
  languages: [
    { code: "fr", label: "Français" },
    { code: "ar", label: "Arabe" },
  ],
  mobileNavOpen: false,
  errors: [],
  success: null,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MOBILE_NAV":
      return { ...state, mobileNavOpen: true };
    case "CLOSE_MOBILE_NAV":
      return { ...state, mobileNavOpen: false };
    case "TOGGLE_MOBILE_NAV":
      return { ...state, mobileNavOpen: !state.mobileNavOpen };
    case "CONTACT_SUCCESS":
      return { ...state, success: action.payload };
    case "CLEAR_SUCCESS":
      return {
        ...state,
        success: null,
      };
    case "ADD_ERROR":
      return { ...state, errors: [...state.errors, action.payload] };
    case "REMOVE_ERROR":
      return {
        ...state,
        errors: [
          state.errors.filter((error, i) => i !== action.payload && error),
        ],
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: [],
      };
    default:
      return state;
  }
};

export default layoutReducer;
