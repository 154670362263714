import React from "react";

import { ReactComponent as MobileSandwitchSvg } from "../assets/mobileNavSandwitch.svg";

export default function MobileSandwitch({ setMobileOpen }) {
  return (
    <div
      className="toggle-mobile-menu-button"
      onClick={() => setMobileOpen((prev) => !prev)}
    >
      <MobileSandwitchSvg />
    </div>
  );
}
