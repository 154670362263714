import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Footer() {
	const { t } = useTranslation();
	return (
		<footer className='footer'>
			<div className='footer-part'>
				<div className='footer-info'>
					<div className='footer-logo'>
						<Link to='/'>
							<img
								src={require('../assets/images/home/LOGO-2.png').default}
								alt=''
							/>
						</Link>
					</div>
					<div className='suivez-nous-box'>
						<div className='legend'>{t('suivez-nous')}</div>
					</div>
				</div>
				<div
					className='footer-description'
					dangerouslySetInnerHTML={{ __html: t('footer-description1') }}></div>
			</div>
			<div className='footer-part'>
				<div className='social-share'>
					{socialIconsTemp.map((icon) => (
						<a href={icon.url} target='_blank' rel='noreferrer'>
							<img src={icon.imgUrl} alt='' />
						</a>
					))}
				</div>
				<div className='footer-links'>
					{t('footerLinks', { returnObjects: true }).map((linkGroup) => (
						<div className='footer-links-div' key={linkGroup.title}>
							{linkGroup.links.length > 0 ? (
								<span className='big-link'>{linkGroup.title}</span>
							) : (
								<Link className='big-link' to={linkGroup.url}>
									{linkGroup.title}
								</Link>
							)}
							{linkGroup.links.length > 0 && (
								<ul>
									{linkGroup.links.map((link) => (
										<li key={link.url}>
											<Link to={link.url}>{link.title}</Link>
										</li>
									))}
								</ul>
							)}
						</div>
					))}
				</div>
			</div>
			<div className='copyright'>{t('copyright')}</div>
		</footer>
	);
}

const socialIcons = [
	{
		url: '',
		imgUrl: require('../assets/images/footer/facebook-logo.svg').default,
	},
	{
		url: '',
		imgUrl: require('../assets/images/footer/twitter-logo.svg').default,
	},
	{
		url: '',
		imgUrl: require('../assets/images/footer/linkedIn-logo.svg').default,
	},
	{
		url: 'https://www.ouedkniss.com/store/13182/eurl-biosphere-lab/accueil',
		imgUrl: require('../assets/images/footer/ouedkniss-logo.svg').default,
	},
];
// temporary one as only one link available
const socialIconsTemp = [
	{
		url: 'https://www.ouedkniss.com/store/13182/eurl-biosphere-lab/accueil',
		imgUrl: require('../assets/images/footer/ouedkniss-logo.svg').default,
	},
];
