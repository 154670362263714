const initialState = {
  categories: [
    {
      name: "EQUIPEMENT ET MATÉRIEL SCINTIFIQUE",
      categories: [
        {
          name: "Agitateur",
          subCategories: [
            {
              name: "A rouleaux",
              products: [{ name: "product 1 ", description: "jdfhqdhfqdgkfj" }],
            },
          ],
        },
      ],
    },
    {
      name: "REACTIF ET PRODUIT CHIMIQUE",
      categories: [
        {
          name: "Agitateur",
          subCategories: [
            {
              name: "A rouleaux",
              products: [{ name: "product 1 ", description: "jdfhqdhfqdgkfj" }],
            },
          ],
        },
      ],
    },
    {
      name: "VERRERIE ET CONSOMMABLE",
      categories: [
        {
          name: "Agitateur",
          subCategories: [
            {
              name: "A rouleaux",
              products: [{ name: "product 1 ", description: "jdfhqdhfqdgkfj" }],
            },
          ],
        },
      ],
    },
    {
      name: "BALANCE DE LABORATOIRE",
      categories: [
        {
          name: "Agitateur",
          subCategories: [
            {
              name: "A rouleaux",
              products: [{ name: "product 1 ", description: "jdfhqdhfqdgkfj" }],
            },
          ],
        },
      ],
    },
    {
      name: "EQUIPEMENT DE SÉCURITÉ LABORATOIRE",
      categories: [
        {
          name: "Agitateur",
          subCategories: [
            {
              name: "A rouleaux",
              products: [{ name: "product 1 ", description: "jdfhqdhfqdgkfj" }],
            },
          ],
        },
      ],
    },
  ],
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default categoriesReducer;
