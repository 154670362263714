import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeContact from '../components/HomeContact';

import HomeSection from '../components/HomeSection';
import SectionTitle from '../components/SectionTitle';
import Slider from '../components/Slider';

export default function HomePage() {
	const { t } = useTranslation();
	const [background, setBackground] = React.useState(
		'EQUIPEMENT ET MATÉRIEL SCINTIFIQUE',
	);

	const categories = t('categories', { returnObjects: true });

	return (
		<div className='home-page'>
			<Slider />

			<div className='home-features'>
				<SectionTitle title={t('home-sommaire')} description={t('home-sub')} />

				<div className='feature-images'>
					{categories.map((category) => (
						<div className='one-feature' key={category.name}>
							<img
								width={100}
								height={100}
								src={
									require(`../assets/images/home/${category.imgName}.png`)
										.default
								}
								alt={t(category.name)}
							/>
							<p>{t(category.name)}</p>
						</div>
					))}
				</div>
			</div>
			<div className={`bg-switcher`}>
				{t('sections', { returnObjects: true }).map((section) => (
					<div
						className={`${
							background === section.imgName ? 'bg-visible' : 'bg-hidden'
						} bg-container `}>
						<img
							key={section.name}
							src={require(`../assets/images/home/${background}.jpg`).default}
							alt=''
						/>
					</div>
				))}
			</div>

			{t('sections', { returnObjects: true }).map((section) => (
				<HomeSection
					key={section.name}
					section={section}
					setBackground={setBackground}
				/>
			))}

			<HomeContact />
		</div>
	);
}
