import React from 'react';

export default function Slider() {
	const [current, setCurrent] = React.useState(1);

	React.useEffect(() => {
		const sliderInterval = setInterval(function () {
			if (current === slides.length) {
				setCurrent(1);
			} else {
				setCurrent((prevCurrent) => prevCurrent + 1);
			}
		}, 5000);
		return () => {
			clearInterval(sliderInterval);
		};
	}, [current]);

	if (!Array.isArray(slides) || slides.length <= 0) {
		return null;
	}
	return (
		<div className='slideshow'>
			<div className='carousel-indicators'>
				{slides.map((slide) => (
					<div
						key={slide}
						className={current === slide && 'active'}
						onClick={() => {
							setCurrent(slide);
						}}
					/>
				))}
			</div>
			{slides.map((slide) => (
				<div
					className={`${
						slide === current ? 'visible' : 'hidden'
					} carousel-inner`}
					key={slide}>
					<div className='carousel-item'>
						<img
							className='carousel-img'
							src={require(`../assets/images/home/slide--${slide}.jpg`).default}
							alt=''
						/>
					</div>
				</div>
			))}
		</div>
	);
}

const slides = [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12];
