import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import MobileSandwitch from './MobileSandwitch';

function MobileMenu({ headerLinks, mobileOpen, setMobileOpen }) {
	const { t } = useTranslation();
	return (
		<ul className={`mobile-menu${mobileOpen ? ' mobile-menu-open' : ''}`}>
			<MobileSandwitch setMobileOpen={setMobileOpen} />
			<div className='mobile-menu-links'>
				{headerLinks.map((linkGroup) => (
					<li className='dropdown' key={linkGroup.title}>
						{linkGroup.links.length > 0 ? (
							<span className='dropdown-toggle'>{t(linkGroup.title)}</span>
						) : (
							<NavLink
								exact
								className='dropdown-toggle dropdown-toggle-link'
								activeClassName='dropdown-toggle-link-active'
								to={linkGroup.url}
								onClick={() => setMobileOpen(false)}>
								{t(linkGroup.title)}
							</NavLink>
						)}

						{linkGroup.links.length > 0 && (
							<ul className='dropdown-menu'>
								{linkGroup.links.map((link) => (
									<li className='dropdown-item' key={link.url}>
										<NavLink
											exact
											className='dropdown-toggle dropdown-toggle-link'
											activeClassName='dropdown-toggle-link-active'
											to={link.url}
											onClick={() => setMobileOpen(false)}>
											{t(link.title)}
										</NavLink>
									</li>
								))}
							</ul>
						)}
					</li>
				))}

				<li className='dropdown'>
					<span className='dropdown-toggle'>{t('documentations')}</span>
					<ul className='dropdown-menu'>
						<li>
							<NavLink
								exact
								className='dropdown-toggle dropdown-toggle-link'
								activeClassName='dropdown-toggle-link-active'
								to='#'>
								{t('catalogue')}
							</NavLink>
						</li>
					</ul>
				</li>
			</div>
		</ul>
	);
}

export default MobileMenu;
