import React from 'react';
import HomeContact from '../components/HomeContact';
import { useTranslation } from 'react-i18next';
import InView from 'react-intersection-observer';

export default function ClientsPage() {
	const { t } = useTranslation();
	return (
		<div className='clients-page'>
			<div class='jumbotron'>
				<img
					src={require('../assets/images/clients/SLIDE.jpg').default}
					alt=''
				/>
			</div>
			<div className='clients-section header-before header-after'>
				<InView threshold={0.25}>
					{({ ref, inView }) => (
						<>
							<h2
								ref={ref}
								className={inView ? 'animIn' : 'animOut'}
								dangerouslySetInnerHTML={{
									__html: t('clients-page-title'),
								}}></h2>
						</>
					)}
				</InView>
				<p dangerouslySetInnerHTML={{ __html: t('clients-page-sub') }}></p>
			</div>

			<div className='flex-2-div'>
				<div className='side text-side'>
					<div className='header-before-left'>
						<InView threshold={0.25}>
							{({ ref, inView }) => (
								<>
									<h2
										ref={ref}
										className={inView ? 'animIn' : 'animOut'}
										dangerouslySetInnerHTML={{
											__html: t('clients-page-title2'),
										}}></h2>
								</>
							)}
						</InView>
						<p dangerouslySetInnerHTML={{ __html: t('clients-page-sub2') }}></p>
						<ul
							dangerouslySetInnerHTML={{
								__html: t('clients-page-sub2-list'),
							}}></ul>

						<InView threshold={0.25}>
							{({ ref, inView }) => (
								<>
									<h3
										ref={ref}
										className={inView ? 'animIn' : 'animOut'}
										dangerouslySetInnerHTML={{
											__html: t('clients-page-title3'),
										}}></h3>
								</>
							)}
						</InView>

						<p
							dangerouslySetInnerHTML={{
								__html: t('clients-page-sub3'),
							}}></p>

						<InView threshold={0.25}>
							{({ ref, inView }) => (
								<>
									<h3
										ref={ref}
										className={inView ? 'animIn' : 'animOut'}
										dangerouslySetInnerHTML={{
											__html: t('clients-page-title4'),
										}}></h3>
								</>
							)}
						</InView>

						<ul
							dangerouslySetInnerHTML={{
								__html: t('clients-page-list4'),
							}}></ul>
					</div>
				</div>
				<div className='side img-side'>
					<img
						src={require('../assets/images/clients/nos-clients.jpg').default}
						alt=''
					/>
				</div>
			</div>

			<HomeContact />
		</div>
	);
}
