import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

export default function SectionTitle({ title, description = '' }) {
	const [showed, setShowed] = useState(false);
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0.8,
	});

	React.useEffect(() => {
		//console.log(inView);
		inView ? setShowed(true) : setShowed(false);
	}, [inView]);
	return (
		<div className='header header-before header-after'>
			<h2
				ref={ref}
				className={`section-title ${showed ? 'animIn' : 'animOut'}`}>
				{title}
			</h2>
			<p className='text-center'>{description}</p>
		</div>
	);
}
