import React from 'react';
import HomeContact from '../components/HomeContact';
import { useTranslation } from 'react-i18next';
import InView from 'react-intersection-observer';

export default function BrandsPage() {
	const { t } = useTranslation();
	return (
		<div className='brands-page'>
			<div class='jumbotron brands-section'>
				<div className='header-before header-after'>
					<InView threshold={0.25}>
						{({ ref, inView, entry }) => (
							<>
								<h2 ref={ref} className={inView ? 'animIn' : 'animOut'}>
									{t('nos-marques')}
								</h2>
							</>
						)}
					</InView>
					<p dangerouslySetInnerHTML={{ __html: t('nos-marques-text') }}></p>
				</div>
			</div>

			<img
				alt='nos marques'
				src={require('../assets/images/brands/les-logos.jpg').default}
				className='brands-image'
			/>

			<HomeContact />
		</div>
	);
}
