import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import layoutReducer from "./layout/layoutReducer";
import categoriesReducer from "./products/productsReducer";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  layout: layoutReducer,
  products: categoriesReducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);
