import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInView } from 'react-intersection-observer';
import CustomButton from './CustomButton';

export default function HomeSection({ section, setBackground }) {
	const [showed, setShowed] = useState(false);
	const { t } = useTranslation();
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0.8,
	});

	React.useEffect(() => {
		if (inView) {
			setBackground(section.imgName);
			setShowed(true);
		} else {
			setShowed(false);
		}
	}, [inView, section, setBackground]);

	return (
		<div className='section-text-image dynamic-tag'>
			<div className='constraintBox'>
				<div className='framedIcon'>
					<img
						src={
							require(`../assets/images/home/${section.imgName}.svg`).default
						}
						alt=''
					/>
				</div>

				<div ref={ref} className='framedText'>
					<h2 className={showed ? 'animIn' : 'animOut'}>{t(section.name)}</h2>
					<p>{t(section.description)}</p>
					<CustomButton title={t('know-more')} color='primary' to={t(section.url)} />
				</div>
			</div>
		</div>
	);
}
