import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import AboutPage from '../pages/About.page';
import BrandsPage from '../pages/Brands.page';
import CareerPage from '../pages/Career.page';
import ClientsPage from '../pages/Clients.page';
import ContactPage from '../pages/Contact.page';
import HomePage from '../pages/home.page';
import ProductsPage from '../pages/Products.page';

export default function Main() {
	return (
		<main>
			<Switch>
				<Route exact path='/'>
					<HomePage />
				</Route>
				<Route path='/about'>
					<AboutPage />
				</Route>
				<Route path='/brands'>
					<BrandsPage />
				</Route>
				<Route path='/career'>
					<CareerPage />
				</Route>
				<Route path='/clients'>
					<ClientsPage />
				</Route>

				<Route path='/contact'>
					<ContactPage />
				</Route>
				<Route path='/Products/:name' component={ProductsPage} />
				<Route>
					<Redirect to='/' />
				</Route>
			</Switch>
		</main>
	);
}
