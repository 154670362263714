import React from 'react';

export default function CustomInput({
	fullWidth,
	name,
	required,
	filled,
	textArea,
	...props
}) {
	return (
		<div className={`${fullWidth ? 'fullWidth ' : ''}input-select-container`}>
			<h6 className={`${filled ? 'filled' : ''}`}>
				{name} {required && '*'}
			</h6>

			{textArea ? <textarea {...props} /> : <input {...props} />}
		</div>
	);
}
