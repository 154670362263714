import React, { useState } from 'react';

import CustomInput from '../components/CustomInput';
import CustomSelect from '../components/CustomSelect';
import CustomButton from '../components/CustomButton';

import HomeContact from '../components/HomeContact';

import { useTranslation } from 'react-i18next';
import InView from 'react-intersection-observer';

import CustomFileInput from '../components/CustomFileInput';
import useFetch from '../utils/useFetch';

export default function CareerPage() {
	const { t } = useTranslation();
	const { carrier, request } = useFetch();
	const formData = new FormData();
	const [valuse, setValues] = useState({
		civilite: null,
		prenom: null,
		nom: null,
		email: null,
		phone: null,
		cv: null,
		motivationLetter: null,
		inPost: null,
		availableDate: null,
		jobSought: null,
		salary: null,
	});
	const [error, setError] = useState(null);

	function validateEmail(emailAdress) {
		// eslint-disable-next-line
		let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (emailAdress.match(regexEmail)) {
			return true;
		} else {
			return false;
		}
	}

	const submit = async () => {
		if (
			!valuse.availableDate ||
			!valuse.civilite ||
			!valuse.cv ||
			!valuse.email ||
			!valuse.inPost ||
			!valuse.jobSought ||
			!valuse.motivationLetter ||
			!valuse.nom ||
			!valuse.phone ||
			!valuse.prenom ||
			!valuse.salary
		) {
			//console.log(valuse);
			return setError('Veuillez remplir tous les champs requis');
		}
		if (!validateEmail(valuse.email)) {
			return setError('Merci de fournir un email valide');
		}

		setError(null);
		formData.append('civilite', valuse.civilite);
		formData.append('availableDate', valuse.availableDate);
		formData.append('inPost', valuse.inPost);
		formData.append('email', valuse.email);
		formData.append('phone', valuse.phone);
		formData.append('nom', valuse.nom);
		formData.append('prenom', valuse.prenom);
		formData.append('jobSought', valuse.jobSought);
		formData.append('salary', valuse.salary);
		formData.append('cv', valuse.cv);
		formData.append('motivationLetter', valuse.motivationLetter);
		await carrier(formData);
	};

	return (
		<div className=' contact-page career-page'>
			<div class='jumbotron'>
				<img
					src={require('../assets/images/career/SLIDE.jpg').default}
					alt=''
				/>
			</div>
			<div className='career-section'>
				<div className='header-before header-after'>
					<InView threshold={0.25}>
						{({ ref, inView, entry }) => (
							<>
								<h2
									ref={ref}
									className={inView ? 'animIn' : 'animOut'}
									dangerouslySetInnerHTML={{ __html: t('career-title') }}></h2>
							</>
						)}
					</InView>
					<p dangerouslySetInnerHTML={{ __html: t('career-sub') }}></p>
				</div>
			</div>

			<div className='form'>
				<div className='header-before centered-content horaire'>
					<InView threshold={0.25}>
						{({ ref, inView, entry }) => (
							<>
								<h2
									ref={ref}
									className={inView ? 'animIn' : 'animOut'}
									dangerouslySetInnerHTML={{
										__html: t('career-posts', { returnObjects: true }).title,
									}}></h2>
							</>
						)}
					</InView>
					{t('career-posts', { returnObjects: true }).posts.map((post) => (
						<p
							style={{ marginTop: 25 }}
							dangerouslySetInnerHTML={{ __html: post }}></p>
					))}
				</div>

				<h6
					style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 30 }}
					dangerouslySetInnerHTML={{ __html: t('pre-form') }}></h6>
				<div className='form' enctype='multipart/form-data'>
					<div className='group'>
						<div className='group-inputs'>
							<CustomSelect
								name={t('civilité')}
								required
								filled={valuse.civilite}
								onChange={(e) => {
									setValues({ ...valuse, civilite: e.target.value });
									
								}}>
								<option
									value='mr'
									dangerouslySetInnerHTML={{ __html: t('mr') }}></option>
								<option
									value='mme'
									dangerouslySetInnerHTML={{ __html: t('mme') }}></option>
								<option
									value='mlle'
									dangerouslySetInnerHTML={{ __html: t('mlle') }}></option>
							</CustomSelect>

							<CustomInput
								filled={valuse.prenom}
								onChange={(e) => {
									setValues({ ...valuse, prenom: e.target.value });						
								}}
								name={t('prénom')}
								required
							/>
							<CustomInput
								filled={valuse.nom}
								onChange={(e) => {
									setValues({ ...valuse, nom: e.target.value });							
								}}
								name={t('nom')}
								required
							/>
							<CustomInput
								filled={valuse.phone}
								onChange={(e) => {
									setValues({ ...valuse, phone: e.target.value });									
								}}
								name={t('phone number')}
								required
							/>
						</div>
					</div>
					<div className='group'>
						<div className='group-inputs'>
							<CustomInput
								filled={valuse.email}
								onChange={(e) => {
									setValues({ ...valuse, email: e.target.value });									
								}}
								name={t('email')}
								fullWidth
								required
							/>
							<CustomInput
								filled={valuse.inPost}
								onChange={(e) => {
									setValues({ ...valuse, inPost: e.target.value });									
								}}
								name={t('in-poste')}
								fullWidth
								required
							/>
							<CustomInput
								filled={valuse.availableDate}
								onChange={(e) => {
									setValues({ ...valuse, availableDate: e.target.value });								
								}}
								name={t('availability-date')}
								fullWidth
								required
							/>
							<CustomInput
								filled={valuse.jobSought}
								onChange={(e) => {
									setValues({ ...valuse, jobSought: e.target.value });									
								}}
								name={t('job-sought')}
								fullWidth
								required
							/>
							<CustomInput
								filled={valuse.salary}
								onChange={(e) => {
									setValues({ ...valuse, salary: e.target.value });									
								}}
								name={t('salary')}
								fullWidth
								required
							/>
							<CustomFileInput
								onChange={(e) => {
									setValues({ ...valuse, cv: e.target.files[0] });
								}}
								name={t('cv')}
								fullWidth
								required
							/>
							<CustomFileInput
								onChange={(e) => {
									setValues({ ...valuse, motivationLetter: e.target.files[0] });
								}}
								name={t('motivation-letter')}
								fullWidth
								required
							/>
						</div>
					</div>
					<CustomButton
						loading={request === 'loading'}
						onClick={submit}
						title={t('envoyer')}
						style={{ display: 'block', margin: 'auto' }}
					/>
					{(error || request === 'error') && (
						<div
							style={{
								margin: 5,
								background: '#ffa5a5',
								padding: 12,
								color: '#640000',
							}}>
							{error || "quelque chose s'est mal passé"}
						</div>
					)}
					{request === 'done' && (
						<div
							style={{
								margin: 5,
								background: '#abffa5',
								padding: 12,
								color: '#00640c',
							}}>
							formulaire envoyé avec succès
						</div>
					)}
				</div>
			</div>
			<HomeContact />
		</div>
	);
}
