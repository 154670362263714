import React, { useState } from 'react';

import CustomInput from '../components/CustomInput';
import CustomSelect from '../components/CustomSelect';
import CustomButton from '../components/CustomButton';
import { useTranslation } from 'react-i18next';
import InView from 'react-intersection-observer';
import villes from '../assets/json-api/dz-wilayas.json';
import useFetch from '../utils/useFetch';

export default function ContactPage() {
	const { contact, request } = useFetch();
	const { t } = useTranslation();
	const [valuse, setValues] = useState({
		civilite: null,
		prenom: null,
		nom: null,
		societe: null,
		profession: null,
		ville: null,
		commune: null,
		email: null,
		phone: null,
		message: null,
	});
	const [error, setError] = useState(null);

	function validateEmail(emailAdress) {
		// eslint-disable-next-line
		let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (emailAdress.match(regexEmail)) {
			return true;
		} else {
			return false;
		}
	}

	const submit = async () => {
		if (
			!valuse.civilite ||
			!valuse.email ||
			!valuse.message ||
			!valuse.nom ||
			!valuse.prenom ||
			!valuse.profession ||
			!valuse.societe ||
			!valuse.ville
		) {
			return setError('Veuillez remplir les champs requis');
		}
		if (!validateEmail(valuse.email)) {
			return setError('merci de fournir un email valide');
		}

		setError(null);
		await contact(valuse);
	};
	return (
		<div className='contact-page'>
			<div class='jumbotron'>
				<img
					src={require('../assets/images/contact/SLIDE.jpg').default}
					alt=''
				/>
			</div>
			<div className='contact-section'>
				<div className='header-before header-after'>
					<InView threshold={0.25}>
						{({ ref, inView, entry }) => (
							<>
								<h2 ref={ref} className={inView ? 'animIn' : 'animOut'}>
									{t('contactez-nous')}
								</h2>
							</>
						)}
					</InView>
					<p
						style={{ color: '#00000094' }}
						dangerouslySetInnerHTML={{
							__html: t('contactez-nous-page-sub'),
						}}></p>
				</div>
				<div className='contact-infos'>
					<p
						className='bold-paragraph'
						dangerouslySetInnerHTML={{ __html: t('contact-info-header1') }}></p>
					<p dangerouslySetInnerHTML={{ __html: t('info-1') }}></p>
					<p dangerouslySetInnerHTML={{ __html: t('info-2') }}></p>
					<br></br>
					<p
						className='bold-paragraph'
						dangerouslySetInnerHTML={{ __html: t('contact-info-header2') }}></p>
					<p dangerouslySetInnerHTML={{ __html: t('info-3') }}></p>
					<p dangerouslySetInnerHTML={{ __html: t('info-4') }}></p>
					<br></br>
					<p
						className='bold-paragraph'
						dangerouslySetInnerHTML={{ __html: t('contact-info-header3') }}></p>
					<p dangerouslySetInnerHTML={{ __html: t('info-5') }}></p>
					<br></br>
					<p
						className='bold-paragraph'
						dangerouslySetInnerHTML={{ __html: t('contact-info-header4') }}></p>
					<p dangerouslySetInnerHTML={{ __html: t('info-6') }}></p>
				</div>
			</div>

			<div className='form'>
				<div className='header-before centered-content horaire'>
					<InView threshold={0.25}>
						{({ ref, inView, entry }) => (
							<>
								<h2
									ref={ref}
									className={inView ? 'animIn' : 'animOut'}
									dangerouslySetInnerHTML={{
										__html: t('working-hours-title'),
									}}></h2>
							</>
						)}
					</InView>
					<p
						style={{ marginTop: 25 }}
						dangerouslySetInnerHTML={{ __html: t('working-hours-lead') }}></p>
				</div>

				<h6
					style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 30 }}
					dangerouslySetInnerHTML={{ __html: t('pre-form') }}></h6>
				<div className='group'>
					<div className='group-inputs'>
						<CustomSelect
							name={t('civilité')}
							required
							filled={valuse.civilite}
							onChange={(e) =>
								setValues({ ...valuse, civilite: e.target.value })
							}>
							<option
								value='mr'
								dangerouslySetInnerHTML={{ __html: t('mr') }}></option>
							<option
								value='mme'
								dangerouslySetInnerHTML={{ __html: t('mme') }}></option>
							<option
								value='mlle'
								dangerouslySetInnerHTML={{ __html: t('mlle') }}></option>
						</CustomSelect>

						<CustomInput
							filled={valuse.prenom}
							onChange={(e) => setValues({ ...valuse, prenom: e.target.value })}
							name={t('prénom')}
							required
						/>
						<CustomInput
							filled={valuse.nom}
							onChange={(e) => setValues({ ...valuse, nom: e.target.value })}
							name={t('nom')}
							required
						/>
						<CustomInput
							filled={valuse.societe}
							onChange={(e) =>
								setValues({ ...valuse, societe: e.target.value })
							}
							name={t('société')}
							required
						/>
						<CustomSelect
							filled={valuse.profession}
							onChange={(e) =>
								setValues({ ...valuse, profession: e.target.value })
							}
							name={t('profession')}
							fullWidth
							required>
							{t('professions', { returnObjects: true }).map((profession) => (
								<option value={profession.value}>{profession.name}</option>
							))}
						</CustomSelect>
					</div>
				</div>
				<div className='group'>
					<div className='group-inputs'>
						<CustomSelect
							name={t('ville')}
							required
							fullWidth
							filled={valuse.ville}
							onChange={(e) => setValues({ ...valuse, ville: e.target.value })}>
							{villes.map((ville) => (
								<option value={ville.id}>
									{localStorage.getItem('i18nextLng') === 'ar'
										? ville.ar_name
										: ville.name}
								</option>
							))}
						</CustomSelect>
						<CustomInput
							filled={valuse.commune}
							onChange={(e) =>
								setValues({ ...valuse, commune: e.target.value })
							}
							name={t('commune')}
							fullWidth
						/>
						<CustomInput
							filled={valuse.email}
							onChange={(e) => setValues({ ...valuse, email: e.target.value })}
							name={t('email')}
							fullWidth
							required
						/>
						<CustomInput
							filled={valuse.phone}
							onChange={(e) => setValues({ ...valuse, phone: e.target.value })}
							name={t('phone number')}
							fullWidth
						/>
						<CustomInput
							filled={valuse.message}
							onChange={(e) =>
								setValues({ ...valuse, message: e.target.value })
							}
							name={t('message')}
							fullWidth
							required
							textArea
							rows='5'
							cols='33'
						/>
					</div>
				</div>

				<CustomButton
					onClick={submit}
					loading={request === 'loading'}
					title='ENVOYER'
					style={{ display: 'block', margin: 'auto' }}
				/>
				{(error || request === 'error') && (
					<div
						style={{
							margin: 5,
							background: '#ffa5a5',
							padding: 12,
							color: '#640000',
						}}>
						{error || "quelque chose s'est mal passé"}
					</div>
				)}
				{request === 'done' && (
					<div
						style={{
							margin: 5,
							background: '#abffa5',
							padding: 12,
							color: '#00640c',
						}}>
						formulaire envoyé avec succès
					</div>
				)}
			</div>
			<div className='apres'>
				<div className='header-before' style={{ marginBottom: 50 }}>
					<InView threshold={0.25}>
						{({ ref, inView, entry }) => (
							<h2
								ref={ref}
								className={inView ? 'animIn' : 'animOut'}
								dangerouslySetInnerHTML={{
									__html: t('contact-footer-title'),
								}}></h2>
						)}
					</InView>
					<p
						dangerouslySetInnerHTML={{
							__html: t('contact-footer-info-1'),
						}}></p>
				</div>

				<InView threshold={0.25}>
					{({ ref, inView, entry }) => (
						<p
							ref={ref}
							className={inView ? 'animIn' : 'animOut'}
							style={{ fontWeight: 'bold', marginBottom: 5 }}
							dangerouslySetInnerHTML={{
								__html: t('contact-footer-info-2'),
							}}></p>
					)}
				</InView>

				<p
					className='text-center'
					dangerouslySetInnerHTML={{ __html: t('contact-footer-info-3') }}></p>
			</div>
		</div>
	);
}
