import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InView from 'react-intersection-observer';
import HomeContact from '../components/HomeContact';

export default function ProductsPage({ match }) {
	const { t } = useTranslation();

	const ref = useRef(null);

	let Products = t('products', {
		returnObjects: true,
	})[match.params.name.toUpperCase()];
	console.log(Products, match.params.name.toUpperCase());
	const [categoryLetter, setCategoryLetter] = React.useState(null);
	const [subCat, setSubCat] = useState({
		name: '',
		products: [],
	});
	return (
		<div className='products-page'>
			<div className='jumbotron'>
				<img
					src={
						require('../assets/images/products/' + match.params.name + '.jpg')
							.default
					}
					alt=''
				/>
			</div>
			<div className='products-section  header-before header-after'>
				<InView threshold={0.25}>
					{({ ref, inView, entry }) => (
						<>
							<h2 ref={ref} className={inView ? 'animIn' : 'animOut'}>
								{match.params.name.replaceAll('_', ' ').toUpperCase()}
							</h2>
						</>
					)}
				</InView>
			</div>

			<div className='filter-container'>
				<h2 className='filter-by-big-title'>PARCOURIRE TOUTE LES RBRIQUE</h2>

				<div className='letter-bubbles-container'>
					{alphabet.map((letter) => (
						<div
							onClick={() => setCategoryLetter(letter.toUpperCase())}
							key={letter}
							className={
								categoryLetter === letter
									? 'filter-letter-bubble filter-letter-bubble-active'
									: 'filter-letter-bubble'
							}>
							{letter}
						</div>
					))}
				</div>

				<div className='categories'>
					{categoryLetter
						? Products?.filter(
								(product) =>
									categoryLetter.toLowerCase() ===
									product.name[0].toLowerCase(),
						  ).map((product, i) => (
								<div
									key={i}
									className={`category ${
										product.products.length > 0 ? 'plus' : ''
									}`}
									onClick={() => {
										console.log(ref);
										if (subCat.name === product.name) {
											setSubCat({
												name: '',
												products: [],
											});
										} else {
											setSubCat({
												name: product.name,
												products: product.products,
											});
											
											ref.current.scrollIntoView({
												behavior: 'smooth',
												block: 'center',
											});
										}
									}}>
									{product.name}
								</div>
						  ))
						: Products.map((product, i) => (
								<div
									key={i}
									className={`category ${
										product.products.length > 0 ? 'plus' : ''
									}`}
									onClick={() => {
										if (subCat.name === product.name) {
											setSubCat({
												name: '',
												products: [],
											});
										} else {
											setSubCat({
												name: product.name,
												products: product.products,
											});
											ref.current.scrollIntoView({
												behavior: 'smooth',
												block: 'center',
											});
										}
									}}>
									{product.name}
								</div>
						  ))}
				</div>
			</div>
			<div className='pink-divider'/>
			<div className='sub-categories-container' id='subs'  ref={ref}>
				{subCat.products && subCat.products.length > 0 && (
					<>
						<div className='subHeader'>
							<h3 className='sub-categories-big-title'>
								<img
									src={require('../assets/images/cat.svg').default}
									width='55px'
									height='55px'
									alt=''
									style={{ marginRight: 10 }}
								/>
								/ categories / {subCat.name}
							</h3>
							<button
								onClick={() =>{
									window.scrollTo({
										top: 725,
										behavior: 'smooth',
									})
									setSubCat({
										name: '',
										products: [],
									});
									}
								}>
								&#8249;
							</button>
						</div>

						<div className='sub-categories'>
							{subCat.products.map((product, i) => (
								<p key={i} className='sub-category'>
									{product}
								</p>
							))}
						</div>
					</>
				)}
			</div>
			<HomeContact />
		</div>
	);
}

const alphabet = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
];
