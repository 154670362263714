import React from 'react';
import { Link } from 'react-router-dom';

export default function CustomButton({
	title,
	color = 'primary',
	onClick,
	to,
	loading,
}) {
	return to ? (
		<Link to={to} target='_blank'>
			<button
				onClick={onClick}
				className={`${color === 'primary' && 'primary-button'} ${
					color === 'secondary' && 'secondary-button'
				}`}>
				{loading ? '...' : title}
			</button>
		</Link>
	) : (
		<button
			onClick={onClick}
			className={`${color === 'primary' && 'primary-button'} ${
				color === 'secondary' && 'secondary-button'
			}`}>
			{loading ? '...' : title}
		</button>
	);
}
