import React from 'react';

export default function CustomFileInput({
	fullWidth,
	name,
	required,
	filled,
	...props
}) {
	return (
		<div
			className={`${
				fullWidth ? 'fullWidth ' : ''
			}input-select-container lapsed`}>
			<h6 className='filled'>
				{name} {required && '*'}
			</h6>

			<input {...props} type='file' />
		</div>
	);
}
