import "./styles.scss";

import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Main from "./layout/Main";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Main />
      <Footer />
    </>
  );
}

export default App;
