import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import CustomButton from './CustomButton';

export default function HomeContact() {
	const [showed, setShowed] = useState(false);
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0.8,
	});

	React.useEffect(() => {
		console.log(inView);
		inView ? setShowed(true) : setShowed(false);
	}, [inView]);
	const { t } = useTranslation();
	return (
		<div className={`home-contact `}>
			<div className={`header header-before`}>
				<h2 ref={ref} className={showed ? 'animIn' : 'animOut'}>
					{t('contactez-nous')}
				</h2>
				<p className='text-center'>{t('contactez-nous-sub')}</p>
			</div>
			<CustomButton title={t('contactez-nous')} to={"/contact"} />
		</div>
	);
}
