import React from 'react';

export default function CustomSelect({
	fullWidth,
	name,
	required,
	children,
	filled,
	...props
}) {
	return (
		<div className={`${fullWidth ? 'fullWidth ' : ''}input-select-container`}>
			<h6 className={`${filled ? 'filled' : ''}`}>
				{name} {required && '*'}
			</h6>
			<select style={{ minWidth: '100%' }} {...props}>
				<option value={''} selected disabled hidden></option>
				{children}
			</select>
		</div>
	);
}
