import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ar from './assets/i18n/ar.json';
import fr from './assets/i18n/fr.json';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'fr',
		debug: false,
		react: { useSuspense: false },
		detection: {
			order: ['queryString', 'cookie'],
			cache: ['cookie'],
		},
		interpolation: {
			escapeValue: false,
		},
		resources: {
			fr: {
				translation: fr,
			},
			ar: {
				translation: ar,
			},
		},
	});

export default i18n;
