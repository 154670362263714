/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useHistory } from 'react-router';
import { Link, NavLink } from 'react-router-dom';

import Logo from '../assets/images/home/LOGO-1.png';

import MobileMenu from './MobileMenu';
import MobileSandwitch from './MobileSandwitch';

import { useTranslation } from 'react-i18next';
import i18n from '../i18nextInit';

export default function Header() {
	const history = useHistory();
	const { t } = useTranslation();

	const [headerFixed, setHeaderFixed] = React.useState(false);
	const [languageBar, setLanguageBar] = React.useState(false);
	const [mobileOpen, setMobileOpen] = React.useState(false);

	React.useEffect(() => {
		document.addEventListener('scroll', handleScroll);
		return () => {
			document.removeEventListener('scroll', handleScroll);
		};
	});

	const handleScroll = (e) => {
		if (window.scrollY > 180)
			setTimeout(() => {
				setHeaderFixed(true);
			}, 0);
		else if (window.scrollY < 100)
			setTimeout(() => {
				setHeaderFixed(false);
			}, 0);
	};

	return (
		<header className={headerFixed && 'header-fixed'}>
			<div className='language-bar'>
				<p onClick={() => setLanguageBar((prev) => !prev)}>
					{t('language')}:
					<span>{t('language') === 'Langue' ? 'Fr' : 'Ar'}</span>
				</p>
				<div className={`dropdown-menu ${languageBar && 'show-dropdown'}`}>
					<a
						href='#'
						className='dropdown-item'
						onClick={() => {
							setLanguageBar(false);
							i18n.changeLanguage('fr');
						}}>
						Francais
					</a>

					<a
						href='#'
						className='dropdown-item'
						onClick={() => {
							setLanguageBar(false);
							i18n.changeLanguage('ar');
						}}>
						العربية
					</a>
				</div>
			</div>

			<div className='header-menu'>
				<div className='logo' onClick={() => history.push('/')}>
					<img src={Logo} alt='biosphere home' />
				</div>

				<MobileSandwitch setMobileOpen={setMobileOpen} />

				<MobileMenu
					headerLinks={t('headerLinks', { returnObjects: true })}
					mobileOpen={mobileOpen}
					setMobileOpen={setMobileOpen}
				/>
				<ul className='menu'>
					{t('headerLinks', { returnObjects: true }).map((linkGroup) => (
						<li className='dropdown' key={linkGroup.title}>
							{linkGroup.links.length > 0 ? (
								<span className='dropdown-toggle'>{t(linkGroup.title)}</span>
							) : (
								<NavLink
									exact
									className='dropdown-toggle dropdown-toggle-link'
									activeClassName='dropdown-toggle-link-active'
									to={linkGroup.url}>
									{t(linkGroup.title)}
								</NavLink>
							)}

							{linkGroup.links.length > 0 ? (
								<ul className='dropdown-menu'>
									{linkGroup.links.map((link) => (
										<li className='dropdown-item' key={link.url}>
											<Link to={link.url}>{t(link.title)}</Link>
										</li>
									))}
								</ul>
							) : null}
						</li>
					))}
					<div className='right-menu'>
						<li className='dropdown'>
							<span className='dropdown-toggle'>{t('documentations')}</span>
							<ul className='dropdown-menu custom-dropdown-position'>
								<li>
									<Link to='#' className='dropdown-item'>
										{t('catalogue')}
									</Link>
								</li>
							</ul>
						</li>
					</div>
				</ul>
			</div>
		</header>
	);
}
